<template>
  <div class="banner" id="card-container">
    <div class="g-container wrapper">
      <div class="top">
        <div class="banner__left">
          <h2 class="g-title title">
            Let's build the future of <span class="g-b">payment</span> together
          </h2>
          <div class="tip">
            Inst is a on-and -off ramps solutions and crypto card provider, we
            believe that the future of world will belongs to Web3, The
            distribution of wealth will be more fair with the promotion of
            cryptocurrency. Moreover，we have strong confidence that it will be
            easier for individuals to mananage, master and invest their own
            asset.
          </div>
        </div>
        <div class="banner__right_container">
          <div class="banner__right">
            <img
              src="@/assets/together_banner.svg"
              class="inst_card_m"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IncorrectApplication',
};
</script>

<style lang="scss" scoped>
.banner {
  display: flex;
  align-items: center;
  margin: 80px 0;
  padding: 120px 0 0;
  @media (max-width: 1080px) {
    height: auto;
    padding: 48px 0 0;
  }
}

.wrapper {
  position: relative;
  padding: 0;
  @media (max-width: 1080px) {
    width: 100%;
  }
}

.top {
  display: flex;
  width: 1280px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1080px) {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}

.banner__left {
  flex: 1;
  margin-right: 62px;
  @media (max-width: 1080px) {
    width: 100%;
    padding: 24px 8px;
    margin-right: 0;
  }
}

.banner__right_container {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 62px;
  @media (max-width: 1080px) {
    margin-left: 0;
  }
}
.banner__right {
  display: flex;
  align-items: flex-end;
  position: relative;
  flex: 1;
  .inst_card_m {
    display: block;
    @media (max-width: 1080px) {
      width: 80%;
      height: auto;
    }
  }
  @media (max-width: 1080px) {
    margin-top: 48px;
    justify-content: center;
  }
}

.title {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 76px;
  margin-bottom: 48px;
  @media (max-width: 1080px) {
    font-size: 30px;
    text-align: center;
    line-height: 50px;
  }
  .g-b {
    color: #0045ff;
  }
}

.tip {
  font-family: "Poppins-Regular";
  font-size: 18px;
  color: rgba(0, 0, 0, 0.3);
  width: 100%;
  margin-bottom: 30px;
  strong {
    color: #000;
  }
}

.l-card-table {
  margin-top: 200px;
}
</style>
