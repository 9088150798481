<template>
  <div class="banner">
    <div class="g-container wrapper">
      <div class="top">
        <div class="banner__top">
          <h2 class="g-title title">Our Values</h2>
        </div>
        <div class="banner__bottom">
          <div class="banner__bottom_list">
            <img src="@/assets/values_banner2.svg" alt="" />
            <div class="list_title">Easy to use</div>
            <div class="tips">
              We pursue the simplicity and ease of use of services and always
              pay attention to the user's operating experience.
            </div>
          </div>
          <div class="banner__bottom_list">
            <img src="@/assets/values_banner3.svg" alt="" />
            <div class="list_title">Reliable</div>
            <div class="tips">
              We have been firmly committed to improving the compliance,
              stability and security of the platform.
            </div>
          </div>
          <div class="banner__bottom_list banner__bottom_list_last">
            <img src="@/assets/values_banner1.svg" alt="" />
            <div class="list_title">Fairness</div>
            <div class="tips">
              We are committed to treating our customers fairly in everything we
              do.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CorrectApplication',
};
</script>

<style lang="scss" scoped>
.banner {
  display: flex;
  align-items: center;
  margin: 240px auto;
  @media (max-width: 1080px) {
    margin: 80px 0;
    height: auto;
  }
}

.wrapper {
  position: relative;
  padding: 0;
  @media (max-width: 1080px) {
    width: 100%;
  }
}

.top {
  display: flex;
  width: 1280px;
  flex-direction: column;
  @media (max-width: 1080px) {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}

.banner__top {
  text-align: left;
  @media (max-width: 1080px) {
    width: 100%;
    margin-right: 0;
    padding: 24px 8px;
  }
}

.banner__bottom {
  display: flex;
  @media (max-width: 1080px) {
    flex-direction: column;
  }
  .banner__bottom_list {
    flex: 1;
    margin-right: 100px;
    @media (max-width: 1080px) {
      margin-right: 0;
      text-align: center;
      padding: 24px 12px;
    }
    img {
      width: 100px;
    }
    .list_title {
      margin-top: 30px;
      font-weight: 500;
      font-size: 36px;
      line-height: 36px;
      @media (max-width: 1080px) {
        margin-top: 24px;
      }
    }
    .tips {
      margin-top: 20px;
      font-size: 18px;
      color: #546175;
      opacity: 0.6;
    }
  }
  .banner__bottom_list_last {
    margin-right: 0;
  }
}

.title {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  margin-bottom: 80px;
  line-height: 50px;
  @media (max-width: 1080px) {
    font-size: 30px;
    text-align: center;
  }
}
</style>
