<template>
  <div class="banner">
    <div class="g-container wrapper">
      <div class="top">
        <div class="banner__left">
          <h2 class="g-title title">Our Mission</h2>
          <div class="tip">
            Giving the world equal access to digital assets to be our mission,
            to bridge the crypto and fiat worlds by making crypto accessible to
            everyone, even those who are new to the technology. lnst is building
            a compliant-centric payment infrastructure for companies,exchanges,
            and wallets to accelerate cryptocurrency adoption. We believe in
            building products that offer a simple, secure and sensible user
            experience.
          </div>
        </div>
        <div class="banner__right_container">
          <div class="banner__right">
            <img
              src="@/assets/commission_banner.svg"
              class="inst_card_m"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpaceAndSize',
};
</script>

<style lang="scss" scoped>
.banner {
  display: flex;
  align-items: center;
  margin-top: 200px;
  @media (max-width: 1080px) {
    margin: 80px 0;
    height: auto;
  }
}

.wrapper {
  position: relative;
  padding: 0;
  @media (max-width: 1080px) {
    width: 100%;
  }
}

.top {
  display: flex;
  width: 1280px;
  align-items: center;
  @media (max-width: 1080px) {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}

.banner__left {
  max-width: 600px;
  margin-right: 150px;
  @media (max-width: 1080px) {
    width: 100%;
    margin-right: 0;
    padding: 24px 8px;
  }
}

.banner__right_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.banner__right {
  display: flex;
  align-items: flex-end;
  position: relative;
  .inst_card_m {
    display: block;
    @media (max-width: 1080px) {
      width: 80%;
      height: auto;
    }
  }
  @media (max-width: 1080px) {
    margin-top: 48px;
    justify-content: center;
  }
}

@keyframes inst_card_animation {
  0% {
    box-shadow: 0px 0px 40px 0px rgba(0, 69, 255, 0);
  }
  100% {
    box-shadow: 0px 0px 40px 0px rgba(0, 69, 255, 0.6);
  }
}

.title {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  margin-bottom: 48px;
  line-height: 50px;
  @media (max-width: 1080px) {
    font-size: 30px;
    text-align: center;
  }
}

.tip {
  font-family: "Poppins-Regular";
  font-size: 18px;
  color: rgba(0, 0, 0, 0.3);
  width: 100%;
  margin-bottom: 30px;
}

.l-card-table {
  margin-top: 200px;
}
</style>
