<template>
  <div class="banner" id="card-container">
    <div class="g-container wrapper">
      <div class="top">
        <div class="banner__left">
          <div class="banner__right">
            <img
              src="@/assets/store_banner.svg"
              class="inst_card_m"
              alt=""
            />
          </div>
        </div>
        <div class="banner__right_container">
          <h2 class="g-title title">Our Story</h2>
          <div class="tip">
            Our team is young and energetic and full of passion for the crypto
            industry, our team members come from all over the world, have
            sufficient experience in the payment industry, crypto industry.
          </div>
          <div class="tip">
            We have created a secure and user-friendly encryption environment,
            offering a wide range of encryption products on an intuitive
            platform. It's built for everyone: making it easy for users to buy
            and sell cryptocurrencies, and for Web3 solution providers to grow
            their businesses with our fiat on/off ramp solution.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpaceAndSize',
};
</script>

<style lang="scss" scoped>
.banner {
  display: flex;
  align-items: center;
  margin-top: 240px;
  @media (max-width: 1080px) {
    margin: 80px 0;
    height: auto;
  }
}

.wrapper {
  position: relative;
  padding: 0;
  @media (max-width: 1080px) {
    width: 100%;
  }
}

.top {
  display: flex;
  width: 1280px;
  align-items: center;
  @media (max-width: 1080px) {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}

.banner__left {
  max-width: 600px;
  margin-right: 62px;
  flex: 1;
  @media (max-width: 1080px) {
    width: 100%;
    margin-right: 0;
    padding: 24px 8px;
  }
}

.banner__right_container {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 62px;
  @media (max-width: 1080px) {
    margin-left: 0;
    padding: 24px 8px;
  }
}
.banner__right {
  display: flex;
  align-items: flex-end;
  position: relative;
  .inst_card_m {
    display: block;
    @media (max-width: 1080px) {
      width: 80%;
      height: auto;
    }
  }
  @media (max-width: 1080px) {
    margin-top: 48px;
    justify-content: center;
  }
}

@keyframes inst_card_animation {
  0% {
    box-shadow: 0px 0px 40px 0px rgba(0, 69, 255, 0);
  }
  100% {
    box-shadow: 0px 0px 40px 0px rgba(0, 69, 255, 0.6);
  }
}

.title {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  margin-bottom: 48px;
  line-height: 50px;
  @media (max-width: 1080px) {
    font-size: 30px;
    text-align: center;
  }
}

.tip {
  font-family: "Poppins-Regular";
  font-size: 18px;
  color: rgba(0, 0, 0, 0.3);
  mix-blend-mode: normal;
  width: 100%;
  margin-bottom: 30px;
}

.l-card-table {
  margin-top: 200px;
}
</style>
