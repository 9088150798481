<template>
  <div>
    <NavHeader />
    <NavHeaderMobile />
    <div class="wrapper">
      <Build />
      <OurStory />
      <Mission />
      <OurValues />
      <!-- <Term /> -->
    </div>
    <NewFooter />
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader.vue';
import NavHeaderMobile from '@/components/NavHeaderMobile.vue';
import Build from '@/views/AboutUS/Build.vue';
import OurStory from '@/views/AboutUS/OurStory.vue';
import Mission from '@/views/AboutUS/Mission.vue';
import OurValues from '@/views/AboutUS/OurValues.vue';
import NewFooter from '@/components/NewFooter.vue';

export default {
  name: 'About',
  components: {
    NavHeader,
    NavHeaderMobile,
    Build,
    OurStory,
    Mission,
    OurValues,
    NewFooter,
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: #ffffff;
}
</style>
